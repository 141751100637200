<template>
  <div class="pbf">
     
     <BrandTabGallery
      :inurl="'/products/' + props.id + '/gallery'"
      :intags="tags"
      />

  </div>
</template>

<script setup>
 
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  }
})
const tags = ref($tagsUtil.tagsBrandSeedGallery)

</script>

<style scoped>
 

</style>
